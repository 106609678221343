import AWSAppSyncClient from 'aws-appsync';
import { GraphQLResult } from '@aws-amplify/api';
import { Utils, Consultations } from '@hellohair/types';
import { NormalizedCacheObject } from './aws.config';
import { LIST_CONSULTATIONS } from './graphql/queries';
import FetchPolicy from 'apollo-client/core/watchQueryOptions';
import {
  BOOK_CONSULTATION_EVENT,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CREATE_CONSULTATION_EVENT,
  DELETE_CONSULTATION_EVENT,
  PLACE_CONSULTATION_EVENT,
} from './graphql/mutations';

export async function listConsultations(
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy.FetchPolicy = 'cache-first'
): Promise<Utils.DBItem<Consultations.CalendarEvent>[]> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const _consultations = await apiClient.query<{ listConsultations: Utils.DBItem<Consultations.CalendarEvent>[] }>({
      query: LIST_CONSULTATIONS,
      fetchPolicy: fetchPolicy,
    });
    console.log('[ConsultationsApi] listConsultations ', _consultations);
    return _consultations?.data?.listConsultations.sort((a, b) => b.createdAt - a.createdAt);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('[ConsultationsApi] listConsultations errors', error);
    if (error?.networkError?.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

/*
export async function createConsultation(
  consultationInput: CalendarEvent,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  console.log('[ConsultationsApi] - createConsultation: ', consultationInput);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: CREATE_CONSULTATION_EVENT,
      variables: { consultationInput },
    })) as GraphQLResult<{ createCustomer: '{}' }>;
    console.log('[ConsultationsApi] - consultationInput - result:', consultationInput, result);

    return result?.data?.createCustomer || '';
  } catch (error) {
    console.error(error);
    throw error;
  }
}
*/

export async function placeConsultation(
  dateTime: string,
  duration: number,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<Consultations.CalendarEvent | undefined> {
  console.log('[ConsultationsApi] - placeConsultation: ', dateTime, duration);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: PLACE_CONSULTATION_EVENT,
      variables: { dateTime, duration },
    })) as GraphQLResult<{ placeConsultation: Consultations.CalendarEvent }>;
    console.log('[ConsultationsApi] - placeConsultation - result:', dateTime, duration, result);

    return result?.data?.placeConsultation;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function removeConsultation(
  eventId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<boolean> {
  console.log('[ConsultationsApi] - deleteConsultation: ', eventId);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: DELETE_CONSULTATION_EVENT,
      variables: { eventId },
    })) as GraphQLResult<{ deleteConsultation: boolean }>;
    console.log('[ConsultationsApi] - deleteConsultation - result:', eventId, result);

    return result?.data?.deleteConsultation || false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function bookConsultation(
  eventId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<Consultations.CalendarEvent | undefined> {
  console.log('[ConsultationsApi] - bookConsultation: ', eventId);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: BOOK_CONSULTATION_EVENT,
      variables: { eventId },
    })) as GraphQLResult<{ bookConsultation: Consultations.CalendarEvent }>;
    console.log('[ConsultationsApi] - bookConsultation - result:', eventId, result);

    return result?.data?.bookConsultation;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}
