import { JsonDataValue } from '../utils';

export type CustomerAddress = {
  streetAddress: string;
  locality: string;
  postalCode: string;
  country: string;
};

export type OrderCustomer = {
  customerId: string;
  customerName: string;
  email?: string;
  firstName?: string;
};

export type OrderCustomerDetails = OrderCustomer & {
  gender: string;
  givenName: string;
  familyName: string;
  email: string;
  address: CustomerAddress;
  birthDate: string;
};

export type OrderProduct = {
  productId: string;
  productName: string;
  unit: string;
  amount: number;
};

export type OrderPayment = {
  paymentId: string;
  subscriptionId?: string;
  isSubscription?: boolean;
  compoundAmount?: number;
  productTitle?: string;
};

export type ProductAllocation = {
  productId: string;
  productName: string;
  productType: string;
  description: string;
  unit: string;
  expireInMonth: number;
};

export type OrderProductDetails = OrderProduct & {
  productGroupId: string;
  allocation: ProductAllocation[];
};

export type SurveyAnswer = {
  question: string;
  answer: string;
  type: string;
};

export type OrderAssets = {
  survey: JsonDataValue;
  answers: JsonDataValue;
  images: string[];
};

export enum OrderRequestState {
  UNKNOWN = 'UNKNOWN',
  IDLE = 'IDLE',
  ALLOWED = 'ALLOWED',
  DENIED = 'DENIED',
  FAILED = 'FAILED',
}

export enum SurveyAssetCategory {
  JSON_TEMPLATE = 'JSON_TEMPLATE',
  JSON_ANSWERS = 'JSON_ANSWERS',
  JSON_IMAGES = 'JSON_IMAGES',
  IMAGE = 'IMAGE',
}

export type SurveyAsset = {
  key: string;
  size: number;
  category: SurveyAssetCategory;
  thumbnail?: string;
  signedUrl?: string;
};

export type OrderRequest = {
  requestId: string;
  name: string;
  templateId: string;
  customer: OrderCustomer;
  product: OrderProduct;
  orderState: OrderRequestState;
  assets: SurveyAsset[];
  processed: boolean;
  payment: OrderPayment;
  comment: string;
  sproofDoctorLink: string;
  sproofPharmacyLink: string;
  history: { event: RequestEvents; time: number }[];
};

export type OrderFollowupRequest = {
  requestId: string;
  name: string;
  templateId: string;
  customer: OrderCustomer;
  product: OrderProduct;
  orderState: OrderRequestState;
  assets: SurveyAsset[];
  processed: boolean;
  paymentId: string;
  comment: string;
  sproofDoctorLink: string;
  sproofPharmacyLink: string;
  history: { event: RequestEvents; time: number }[];
  isVisible: boolean;
};

export type OrderRequestShort = {
  requestId: string;
  name: string;
  templateId: string;
  customerId: string;
  createdAt: number;
  orderState: OrderRequestState;
};

export type OrderRequestDetails = OrderRequest & {
  customer: OrderCustomerDetails;
  qanda: SurveyAnswer[];
  isSubscription: boolean;
};

export type OrderFollowupRequestDetails = OrderFollowupRequest & {
  customer: OrderCustomerDetails;
  qanda: SurveyAnswer[];
  isSubscription: boolean;
};

export type OrderRequestParams = {
  surveyTag: string;
  assets: OrderAssets;
  productId: string;
  customerId: string;

  paymentId: string;
};

export type OrderRequestEvent = {
  requestId: string;
  userId: string;
  action: string;
  source: string;
};
export enum SurveySubject {
  ALLOW_ORDER_REQUEST = 'ALLOW_ORDER_REQUEST',
  DENY_ORDER_REQUEST = 'DENY_ORDER_REQUEST',
}

export type TreatmentResponse = {
  treatmentId: string;
  treatmentType: 'SINGLE' | 'MULTIPLE';
  expiryDate: number;
  consumableAmount: number;
  consumedAmount: number;
};

export type OrderRequestResponse = {
  surveyTag: string;
  state: OrderRequestState;
  previousState: OrderRequestState;
  treatment?: TreatmentResponse;
};

export enum RequestEvents {
  REZEPT_GENERATE = 'RezeptGenerate',
  REZEPT_SIGN = 'RezeptSign',
  REQUEST_GENERATE = 'RequestGenerate',
  INVOICE_GENERATE = 'InvoiceGenerate',
  CHARGE_PAYED = 'ChargePayed',
  CHARGE_FAILED = 'ChargeFailed',
  CHARGE_REFUND = 'ChargeRefund',
}
