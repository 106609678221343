import { CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';

/**
 * Contains logic and aws queries for user authorization
 */
export interface IUser {
  id: string;
  email: string;
  phoneNumber?: string;
  title: string;
  firstName: string;
  lastName: string;
  job: string;
  gender: string;
  address: {
    street: string;
    houseNumber: string;
    zip: string;
    city: string;
  };
  roles: string[];
}

/**
 * User Sign In
 * @param email : users email address
 * @param password : users password
 */
export async function signIn(email: string, password: string): Promise<[CognitoUser, string?]> {
  try {
    const user: CognitoUser = await Auth.signIn(email, password);
    if (user.challengeName === 'MFA_SETUP') {
      console.log('Setup MFA');
      return [user, await Auth.setupTOTP(user)];
    }
    console.log('signIn:: success', user);
    return [user];
  } catch (error) {
    console.log('signIn:: error', error);
    throw error;
  }
}

export async function verifyTOTP(user: CognitoUser, code: string) {
  await Auth.verifyTotpToken(user, code);
  await Auth.setPreferredMFA(user, 'TOTP');
}

/**
 * User Sign Out
 */
export async function signOut(): Promise<boolean> {
  try {
    await Auth.signOut();
    console.log('signOut:: success');
    return true;
  } catch (error) {
    console.log('error signing out: ', error);
    throw error;
  }
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function updateUser(user: CognitoUser, attributes: any) {
  const res = await Auth.updateUserAttributes(user, attributes);
  console.log('updateUser::', res);
  return res;
}

export async function forgotPassword(email: string): Promise<CognitoUser> {
  try {
    const user = await Auth.forgotPassword(email);
    console.log('forgotPassword:: success', user);
    return user;
  } catch (error) {
    console.log('forgotPassword:: error', error);
    throw error;
  }
}

export async function resetPassword(code: string, password: string, email: string): Promise<void> {
  try {
    await Auth.forgotPasswordSubmit(email, code, password);
    console.log('resetPassword:: success');
  } catch (error) {
    console.log('resetPassword:: error', error);
    throw error;
  }
}

// AWS TypeDef is any itself
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getCurrentAuthenticatedUser(): Promise<any> {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    console.log('getCurrentAuthenticatedUser:: error', error);
    return undefined;
  }
}

export enum AuthEvent {
  'SIGN_IN' = 'signIn',
  'SIGN_UP' = 'signUp',
  'SIGN_OUT' = 'signOut',
  'SIGN_IN_FAILURE' = 'signIn_failure',
  'TOKEN_REFRESH' = 'tokenRefresh',
  'TOKEN_REFRESH_FAILURE' = 'tokenRefresh_failure',
  'CONFIGURED' = 'configured',
}

/**
 * Options for Aws auth event listener
 */
export interface AuthEventOptions {
  /**
   * If set to true. Listener removes it self after got event once.
   */
  useOnce?: boolean;
}

/**
 * Formats CognitoUser into AppUser
 * @param cognitoUser
 */
export function formatUser(cognitoUser: CognitoUser): Promise<IUser> {
  return new Promise((resolve, reject) => {
    if (!cognitoUser) reject(new Error('No Cognito user received.'));

    cognitoUser.getUserAttributes((error, attributes) => {
      if (error) {
        reject(error);
        return;
      }

      if (!attributes) {
        reject(new Error('No attributes found for user.'));
        return;
      }

      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      const user: IUser = {
        id: cognitoUser.getUsername(),
        email: attributes.find((f) => f.getName() === 'email')!.getValue(),
        phoneNumber: attributes.find((f) => f.getName() === 'custom:contact_phone')?.getValue(),
        title: attributes.find((f) => f.getName() === 'custom:title')?.getValue() ?? '',
        firstName: attributes.find((f) => f.getName() === 'given_name')?.getValue() ?? '',
        lastName: attributes.find((f) => f.getName() === 'family_name')?.getValue() ?? '',
        job: attributes.find((f) => f.getName() === 'custom:job')?.getValue() ?? '',
        gender: attributes.find((f) => f.getName() === 'gender')?.getValue() ?? '',
        address: {
          street: attributes.find((f) => f.getName() === 'custom:street')?.getValue() ?? '',
          houseNumber: attributes.find((f) => f.getName() === 'custom:house_number')?.getValue() ?? '',
          zip: attributes.find((f) => f.getName() === 'custom:zip')?.getValue() ?? '',
          city: attributes.find((f) => f.getName() === 'custom:city')?.getValue() ?? '',
        },
        roles: cognitoUser.getSignInUserSession()?.getAccessToken().payload['cognito:groups'] ?? [],
      };
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
      resolve(user);
    });
  });
}
