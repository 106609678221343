import { GraphQLResult } from '@aws-amplify/api';
import FetchPolicy from 'apollo-client/core/watchQueryOptions';
import AWSAppSyncClient from 'aws-appsync';
import { NormalizedCacheObject } from './aws.config';
import { ADD_USER_TO_GROUP, CREATE_USER, DELETE_USER, REMOVE_USER_FROM_GROUP, UPDATE_USER } from './graphql/mutations';
import { LIST_USERS, LIST_USER_GROUPS } from './graphql/queries';
import { Users } from '@hellohair/types';

export type UserAddInput = {
  email: string;
  role: string;
  phoneNumber?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  job?: string;
  address?: {
    street?: string;
    houseNumber?: string;
    city?: string;
    zip?: string;
  };
  isActive?: boolean;
  hasSproof: boolean;
  factor?: string;
  studio?: string;
};

export type UserUpdateInput = {
  sub: string;
  phoneNumber?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  job?: string;
  gender?: string;
  address?: {
    street?: string;
    houseNumber?: string;
    city?: string;
    zip?: string;
  };
  isActive?: boolean;
  factor?: string;
  studio?: string;
};

export type UserGroupInput = {
  name: string; // sub from User type
  group: string; // name from User Group type
};

export async function listUsers(
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy.FetchPolicy = 'cache-first'
): Promise<Users.BackendUser[]> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const _users = await apiClient.query<{ listUsers: Users.BackendUser[] }>({
      query: LIST_USERS,
      fetchPolicy: fetchPolicy,
    });
    return _users?.data?.listUsers;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('[UsersApi] listUsers errors', error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function createUser(
  userInput: UserAddInput,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<boolean> {
  console.log('[UsersApi] - createUser: ', userInput);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: CREATE_USER,
      variables: { userInput },
    })) as GraphQLResult<{ addUser: boolean }>;
    console.log('[UserApi] - userInput - result:', userInput, result);

    return result?.data?.addUser;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function updateUser(
  userUpdateInput: UserUpdateInput,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  console.log('[UsersApi] - updateUser: ', userUpdateInput);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: UPDATE_USER,
      variables: { userUpdateInput },
    })) as GraphQLResult<{ updateUser: '{}' }>;
    console.log('[UserApi] - userUpdate - result:', userUpdateInput, result);

    return result?.data?.updateUser || '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function deleteUser(
  userId: string,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<boolean> {
  console.log('[UsersApi] - deleteUser: ', userId);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: DELETE_USER,
      variables: { id: userId },
    })) as GraphQLResult<{ deleteUser: boolean }>;
    console.log('[UserApi] - deleteUser - result:', userId, result);

    return result?.data?.deleteUser;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function listUserGroups(
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>,
  fetchPolicy: FetchPolicy.FetchPolicy = 'cache-first'
): Promise<Users.UserGroup[]> {
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const _users = await apiClient.query<{ listUserGroups: Users.UserGroup[] }>({
      query: LIST_USER_GROUPS,
      fetchPolicy: fetchPolicy,
    });
    return _users?.data?.listUserGroups;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.log('[UsersApi] listUserGroups errors', error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function addUserToGroup(
  userGroupInput: UserGroupInput,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  console.log('[UsersApi] - addUserToGroup: ', userGroupInput);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: ADD_USER_TO_GROUP,
      variables: { userGroupInput },
    })) as GraphQLResult<{ addUserToGroup: string }>;
    console.log('[UserApi] - addUserToGroup - result:', userGroupInput, result);

    return result?.data?.addUserToGroup || '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}

export async function removeUserFromGroup(
  userGroupInput: UserGroupInput,
  apiClient?: AWSAppSyncClient<NormalizedCacheObject>
): Promise<string> {
  console.log('[UsersApi] - removeUserFromGroup: ', userGroupInput);
  if (!apiClient) {
    throw new Error('ApiClient not defined');
  }

  try {
    const result = (await apiClient.mutate({
      mutation: REMOVE_USER_FROM_GROUP,
      variables: { userGroupInput },
    })) as GraphQLResult<{ removeUserFromGroup: string }>;
    console.log('[UserApi] - removeUserFromGroup - result:', userGroupInput, result);

    return result?.data?.removeUserFromGroup || '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.networkError.statusCode === 401) {
      window.location.replace('/login');
    }
    throw error;
  }
}
