import { FormControl, SxProps, TextField, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useStyles } from '../../theme/styles.helpers';
import { PatientsStyles } from './Patients.styles';
import { DataContext } from '../../aws/DataProvider';
import { useTranslation } from 'react-i18next';
import { CustomDataGrid } from '../../components/data-grid/CustomDataGrid';
import { Utils } from '@hellohair/types';
import { useRedirectOnAuth } from '../../aws/AuthHooks';
import { AuthState } from '../../aws/AuthProvider';
import { ROUTE } from '../routes';
import { GridRowParams } from '@mui/x-data-grid';
import { CustomModal } from '../../components/modal/CustomModal';
import { UserRequestHistory } from '../../components/modals/user-request-history/UserRequestHistory';

interface PatientsProps {
  /** styles */
  sx?: SxProps<Theme>;
}

interface CustomersListRow {
  customerId: string;
  gender: string;
  email: string;
  fullName: string;
  assignedDoctor: string;
}

type SelectedCustomerType = {
  customerId: string;
  dateCreated: string;
  doctorId: string;
  stripeId: string;
  firstName: string;
  gender: string;
  lastName: string;
};

const Patients: FC<PatientsProps> = () => {
  useRedirectOnAuth([AuthState.UNAUTHORIZED], ROUTE.LOGIN);

  const styles = useStyles(PatientsStyles, {});
  const { t } = useTranslation();
  const [rows, setRows] = useState<CustomersListRow[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerModal, setCustomerModal] = useState<SelectedCustomerType | null>(null);
  const [search, setSearch] = useState<string>('');

  const {
    customersState: [customers],
    usersState: [users],
  } = useContext(DataContext);
  const doctors = users.filter((user) => user.groups.includes('doctor'));
  // TODO: add type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transformToRow: (request: Utils.DBItem<any>) => CustomersListRow = (request: Utils.DBItem<any>) => {
    const assignedDoctor = doctors.find((doctor) => doctor.sub === request.doctorId);

    return {
      customerId: request.customerId,
      gender: t(request.gender),
      email: request.email,
      fullName: request.firstName + ' ' + request.lastName,
      assignedDoctor: assignedDoctor ? assignedDoctor.firstName + ' ' + assignedDoctor.lastName : '',
      dateCreated: new Date(request.dateCreated),
    };
  };

  useEffect(() => {
    setRows(customers.map(transformToRow));
  }, [customers]);

  useEffect(() => {
    const _rows = customers.map(transformToRow).filter((row) => {
      return Object.values(row).some((value) => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(search.toLowerCase());
        }
        return false;
      });
    });
    setRows(_rows);
  }, [search]);

  // TODO: Data from AWS
  const columns = useMemo(
    () => [
      { field: 'gender', headerName: t('Gender'), filterable: false },
      { field: 'email', headerName: t('E-Mail'), filterable: false, flex: 1 },
      { field: 'fullName', headerName: t('Name'), filterable: false, flex: 1 },
      { field: 'assignedDoctor', headerName: t('Doctor'), filterable: false, flex: 1 },
      { field: 'dateCreated', headerName: t('Date created'), type: 'date', filterable: false, flex: 1 },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleRowClicked = (params: GridRowParams) => {
    const selectedCustomer = customers?.find((row) => row.customerId === params.row.customerId);
    setCustomerModal(selectedCustomer);
    setIsModalOpen(true);
  };

  return (
    <Box sx={styles.root} className="Patients-root">
      <Box sx={styles.header}>
        <Typography variant="h3">{t('Patients')}</Typography>
        <FormControl sx={{ width: 300 }} variant="outlined">
          <TextField
            label="Suchen"
            variant="outlined"
            InputLabelProps={{ sx: { textIndent: '10px', fontSize: '18px' } }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </FormControl>
      </Box>

      <CustomDataGrid
        columns={columns}
        rows={rows || []}
        onRowClick={handleRowClicked}
        getRowId={(row) => row.customerId}
        isCellEditable={() => false}
        isRowSelectable={() => false}
        pagination
        autoPageSize
        sx={styles.row}
        ignoreDiacritics
        initialState={{
          sorting: {
            sortModel: [{ field: 'dateCreated', sort: 'desc' }],
          },
        }}
      />
      <CustomModal variant="large" isModalOpen={isModalOpen} onModalClose={() => setIsModalOpen(false)}>
        <UserRequestHistory
          onModalClose={() => setIsModalOpen(false)}
          customerId={customerModal?.customerId || ''}
          firstName={customerModal?.firstName || ''}
          lastName={customerModal?.lastName || ''}
          stripeId={customerModal?.stripeId || ''}
        />
      </CustomModal>
    </Box>
  );
};

export default Patients;
