import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRedirectOnAuth } from '../../aws/AuthHooks';
import { AuthContext, AuthState } from '../../aws/AuthProvider';
import { AddButton } from '../../components/button-components/add-button/AddButton';
import { CustomDataGrid } from '../../components/data-grid/CustomDataGrid';
import { CustomModal } from '../../components/modal/CustomModal';
import { useStyles } from '../../theme/styles.helpers';
import { ROUTE } from '../routes';
import { StudiosStyles } from './Studios.Styles';
import StudioForm from '../../components/modals/studio-form';
import { Studio } from '@hellohair/types';
import { GridRowParams } from '@mui/x-data-grid';
import { DataContext } from '../../aws/DataProvider';

const Studios: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStudio, setSelectedStudio] = useState<Studio | null>(null);
  const { t } = useTranslation();
  const styles = useStyles(StudiosStyles, {});
  const {
    studiosState: [studios],
    usersState: [users],
  } = useContext(DataContext);
  const { userAttributes } = useContext(AuthContext);

  const managingStudios = studios.filter((studio) => studio.managerId === userAttributes?.id);
  const isStudioManager = managingStudios.length > 0;

  useRedirectOnAuth([AuthState.UNAUTHORIZED], ROUTE.LOGIN);

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedStudio(null);
  };

  const handleRowClicked = (params: GridRowParams) => {
    const s = studios.find((studio) => studio.id === params.row.id);
    if (s) setSelectedStudio(s);
    setIsModalOpen(true);
  };

  const mapRows = () => {
    const targetStudios = isStudioManager ? managingStudios : studios;

    return targetStudios.map((s) => {
      const manager = users.find((u) => u.sub === s.managerId);
      if (!manager) return s;
      return { ...s, manager: `${manager.firstName} ${manager.lastName}` };
    });
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="h3">{t('Studio')}</Typography>
        {/*Studio managers can not add new studios. Only admins can add new studios.*/}
        {!isStudioManager && <AddButton onClick={() => setIsModalOpen(true)} />}
      </Box>

      <CustomDataGrid
        columns={[
          {
            field: 'name',
            headerName: t('Name'),
            flex: 1,
          },
          {
            field: 'manager',
            headerName: t('Studio manager'),
            flex: 1,
          },
          {
            field: 'description',
            headerName: t('Description'),
            flex: 1,
          },
        ]}
        rows={mapRows()}
        onRowClick={handleRowClicked}
        isCellEditable={() => false}
        isRowSelectable={() => false}
        getRowId={(row) => row.name}
        pagination
        autoPageSize
        sx={styles.row}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
      />

      <CustomModal isModalOpen={isModalOpen} onModalClose={closeModal} variant="large">
        <StudioForm onModalClose={closeModal} selectedStudio={selectedStudio} isStudioManager={isStudioManager} />
      </CustomModal>
    </Box>
  );
};

export default Studios;
