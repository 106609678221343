import { ComponentStylesWithFns } from '../../theme/styles.helpers';

export const DashboardStyles: ComponentStylesWithFns = {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    overscrollBehavior: 'none',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '112px',
    marginX: 'auto',
    width: '90%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    marginX: 'auto',
    marginBottom: 3,
    width: '90%',
  },
  statsWrapper: {
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr', '1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr'],
    gridColumnGap: 24,
    rowGap: 4,
  },
  statsContainer: {
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // alignItems: 'end',
    overflow: 'clip',
    border: '0.5px solid',
    borderColor: 'white',
    height: '192px',
  },
  chartBox: {
    gridArea: 'Chart',
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 4,
    border: '0.5px solid',
    borderColor: 'white',
  },
  listBox: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '280px',
  },
  link: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 4,
  },
  arrowIcon: {
    fontSize: '16px',
  },
  statsIcon: {},
};
