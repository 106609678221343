import { Button, SvgIcon, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { CSSProperties, FC, useContext } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../aws/AuthProvider';
import { DataContext } from '../../../aws/DataProvider';
import { useStyles } from '../../../theme/styles.helpers';
import FormInput from '../../form-components/form-input/FormInput';
import { Styles } from './styles';
import { Studio, Users } from '@hellohair/types';
import { Close } from '@mui/icons-material';
import FormSelect from '../../form-components/form-select/FormSelect';
import { createStudio, updateStudio } from '../../../aws/RequestsApi';

interface UserFormProps {
  onModalClose: () => void;
  selectedStudio: Studio | null;
  isStudioManager: boolean;
}

const StudioForm: FC<UserFormProps> = ({ onModalClose, selectedStudio, isStudioManager }) => {
  const { t } = useTranslation();
  const styles = useStyles(Styles, {});
  const {
    usersState: [users],
    studiosState: [studios, setStudios],
  } = useContext(DataContext);

  const allDoctorsFromThisStudio = users.filter((user) => {
    const isDoctor = user.groups.includes('doctor');
    const isManager = user.sub === selectedStudio?.managerId;
    const belongsToStudio = user.studio === selectedStudio?.id;
    return isDoctor && (isManager || belongsToStudio);
  });

  console.log('allDoctorsFromThisStudio: ', allDoctorsFromThisStudio);

  const { apiClient } = useContext(AuthContext);
  const { handleSubmit, control } = useForm<Studio>({
    defaultValues: {
      ...selectedStudio,
    },
  });

  // onSubmit
  const onSubmit: SubmitHandler<Studio> = async (data) => {
    if (selectedStudio) {
      const u: Studio = {
        id: selectedStudio.id,
        name: data.name,
        description: data.description,
        managerId: data.managerId,
      };
      updateStudio(u, apiClient)
        .then((id) => {
          const updatedStudio = {
            id: id,
            ...data,
          };

          const updatedStudios = studios.map((studio) => (studio.id === id ? updatedStudio : studio));
          setStudios(updatedStudios);
          onModalClose();
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      createStudio(data, apiClient)
        .then((id) => {
          const newStudio = {
            id: id,
            ...data,
          };

          setStudios([...studios, newStudio]);
          onModalClose();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const onError: SubmitErrorHandler<Users.BackendUser> = (errors) => {
    console.log('[UserDetailView] - onError', errors);
  };

  const doctors = users.filter((user) => user.groups.includes('doctor'));

  return (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography variant="h4">{selectedStudio ? selectedStudio.name : t('Add Studio')}</Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit, onError)} style={styles.form as CSSProperties}>
          <Box>
            <FormInput className="w_100 mg_h_20" name="name" label={t('Name')} control={control} />
            <FormInput className="w_100 mg_h_20" name="description" label={t('Description')} control={control} />
            {/* Studio managers can not change managers, only admin can do it */}
            {!isStudioManager && (
              <FormSelect
                className="w_100 mg_h_20"
                name="managerId"
                label={t('Studio manager')}
                control={control}
                defaultValue={'-'}
                options={[
                  { value: t('No manager'), id: '-' },
                  ...doctors
                    // .filter((d) => {
                    //   const isAlreadyManager = studios.find((studio) => studio.managerId === d.sub);
                    //   // only show doctors that are not already managers
                    //   return !isAlreadyManager;
                    // })
                    .map((d) => ({ id: d.sub, value: `${d.firstName} ${d.lastName}` })),
                ]}
              />
            )}

            <Box mt={2}>
              <Typography mb={1} variant="h3">
                {t('Team')}:
              </Typography>
              {allDoctorsFromThisStudio.map((doctor) => {
                const isManager = doctor.sub === selectedStudio?.managerId;
                return (
                  <Box key={doctor.sub} display={'flex'} justifyContent={'space-between'}>
                    <Typography>
                      {doctor.firstName + ' ' + doctor.lastName} - ({doctor.email})
                    </Typography>
                    <Typography>{isManager ? t('Studio manager') : t('User')}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Button
            fullWidth
            type="submit"
            sx={{
              marginTop: 3,
            }}
          >
            <Typography variant="h3">{t('Done')}</Typography>
          </Button>
        </form>
      </Box>

      {/* Closing Icon */}
      <Box onClick={onModalClose} sx={{ marginLeft: 2, cursor: 'pointer' }}>
        <SvgIcon sx={{ color: 'primary.main' }} component={Close}></SvgIcon>
      </Box>
    </Box>
  );
};

export default StudioForm;
