import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../aws/DataProvider';
import { useStyles } from '../../theme/styles.helpers';
import { DashboardStyles } from './Dashboard2.Styles';
import { useRedirectOnAuth } from '../../aws/AuthHooks';
import { AuthState } from '../../aws/AuthProvider';
import { ROUTE } from '../routes';
import { Box, Link, Stack, SvgIcon, Typography } from '@mui/material';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import { RequestsShortList } from '../../components/requests-short-list/RequestsShortList';
import BarChartStatsTotal from '../../components/bar-chart-custom/BarChartStatsTotal';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { Orders } from '@hellohair/types';

const Dashboard2: FC = () => {
  const { t } = useTranslation();
  const {
    ordersState: [requests],
  } = useContext(DataContext);

  const navigate = useNavigate();
  // TODO: fetch last 3 months from database
  const lastThreeMonthsRequests = requests.filter((request) => {
    return new Date(request.createdAt) >= new Date(new Date().setMonth(new Date().getMonth() - 3));
  });

  const styles = useStyles(DashboardStyles, {});

  const stats = lastThreeMonthsRequests.reduce(
    (a, c) => {
      switch (c.orderState) {
        case Orders.OrderRequestState.IDLE:
          a.open++;
          break;
        case Orders.OrderRequestState.ALLOWED:
          a.allowed++;
          break;
        case Orders.OrderRequestState.DENIED:
          a.denied++;
          break;
      }
      return a;
    },
    {
      open: 0,
      allowed: 0,
      denied: 0,
    }
  );

  useRedirectOnAuth([AuthState.UNAUTHORIZED], ROUTE.LOGIN);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.header}>
        <Typography variant="h3">{t('Dashboard')}</Typography>
      </Box>
      <Box sx={styles.container}>
        <Box sx={styles.statsWrapper}>
          <Box sx={styles.statsContainer}>
            <SvgIcon sx={styles.statsIcon} component={QuizOutlinedIcon}></SvgIcon>
            <Stack direction="column" spacing={1} justifyContent="center" alignItems="left">
              <Typography sx={{ alignContent: 'center' }} variant="h1">
                {stats.open}
              </Typography>
              <Typography variant="h5">{t('IDLE')}</Typography>
            </Stack>
          </Box>
          <Box sx={styles.statsContainer}>
            <SvgIcon sx={styles.statsIcon} component={TaskOutlinedIcon}></SvgIcon>
            <Stack direction="column" spacing={1} justifyContent="center" alignItems="left">
              <Typography variant="h1">{stats.allowed}</Typography>
              <Typography variant="h5">{t('ALLOWED')}</Typography>
            </Stack>
          </Box>
          <Box sx={styles.statsContainer}>
            <SvgIcon sx={styles.statsIcon} component={DisabledByDefaultOutlinedIcon}></SvgIcon>
            <Stack direction="column" spacing={1} justifyContent="center" alignItems="left">
              <Typography variant="h1">{stats.denied}</Typography>
              <Typography variant="h5">{t('DENIED')}</Typography>
            </Stack>
          </Box>
        </Box>

        <Box sx={styles.chartBox}>
          <BarChartStatsTotal requests={lastThreeMonthsRequests} />
        </Box>

        <Box sx={styles.listBox}>
          <Typography variant="h5" marginBottom={2}>
            {t('LastRequests')}
          </Typography>

          <RequestsShortList requests={(lastThreeMonthsRequests || []).slice(0, 4)} />

          <Link
            onClick={() => navigate(ROUTE.REQUESTS)}
            component="button"
            sx={styles.link}
            variant="h6"
            color="inherit"
            fontSize={16}
          >
            {t('AllRequests')}
            <SvgIcon sx={styles.arrowIcon} component={ArrowForwardIcon}></SvgIcon>
          </Link>
        </Box>

        <Box></Box>
      </Box>
    </Box>
  );
};

export default Dashboard2;
